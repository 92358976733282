<template>
  <div class="Export_Page">
    <v-app-bar dense fixed app color="purple darken-4" dark>
      <v-btn to="/menu_manage" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title>เปิดงวดใหม่</v-toolbar-title>
      <v-spacer></v-spacer>
      <strong class="white--text">{{ HostName }}</strong>
    </v-app-bar>

    <v-stepper v-model="step_data">
      <v-stepper-header>
        <v-stepper-step :complete="step_data > 1" step="1">
          ข้อมูลงวด
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step_data > 2" step="2">
          สร้างเลขอั้น
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          สรุป
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-5 pa-0" color="white" height="">
            <!-- form create period -->
            <v-card-text>
              <v-row>
                <v-col cols="12"
                  ><PeriodDtPicker
                    v-model="NewPeriod.Period"
                    label="งวดวันที่"
                  ></PeriodDtPicker
                ></v-col>
                <v-col cols="6">
                  <CloseDTShowPicker
                    v-model="NewPeriod.CloseDTShow"
                    label="เวลาปิด (แสดง)"
                  ></CloseDTShowPicker>
                </v-col>
                <v-col cols="6"
                  ><CloseTimeShowPicker
                    v-model="NewPeriod.CloseTimeShow"
                    label=""
                  ></CloseTimeShowPicker
                ></v-col>
                <v-col cols="12">
                  <v-switch
                    v-model="NewPeriod.IsAutoClose"
                    label="ตั้งปิดอัตโนมัติ"
                  ></v-switch>
                </v-col>
                <v-col cols="6">
                  <CloseDTAutoPicker
                    v-model="NewPeriod.CloseDTAuto"
                    label="เวลาปิด (อัตโนมัติ)"
                  ></CloseDTAutoPicker>
                </v-col>
                <v-col cols="6"
                  ><CloseTimeAutoPicker
                    v-model="NewPeriod.CloseTimeAuto"
                    label=""
                  ></CloseTimeAutoPicker
                ></v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <div class="text-center">
            <v-btn text class="red--text">
              ยกเลิก
            </v-btn>
            <v-btn color="primary" @click="step_data = 2">
              ต่อไป <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-5" color="white" height="">
            <v-card-text>
              <v-row>
                <v-col xl="3" lg="3" md="4" cols="12" class="">
                  <v-row>
                    <v-col cols="12" class="pt-0">
                      <v-select
                        :items="type_items"
                        item-text="text"
                        item-value="value"
                        v-model="selectedType"
                        @change="changedValue"
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="pt-0">
                      <v-select
                        :items="prize_item"
                        item-text="text"
                        item-value="value"
                        v-model="selectedPrize"
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row v-if="selectedPrize == 0 || selectedPrize == 2">
                    <v-col cols="6" class="pt-0">
                      <v-text-field
                        label="จ่ายตรง"
                        type="number"
                        v-model="Prize1"
                        ref="txtPrize1"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pt-0" v-if="selectedType == 3">
                      <v-text-field
                        label="จ่ายโต๊ด"
                        type="number"
                        v-model="Prize2"
                        ref="txtPrize2"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="8" class="pt-0">
                      <v-text-field
                        label="เลข"
                        type="number"
                        v-model="BetNumber"
                        ref="txtBetNumber"
                        v-on:keyup.enter="OnAddBetType()"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="pt-0 text-right">
                      <v-btn color="" @click="OnAddBetType()">
                        <v-icon>mdi-play</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col xl="9" lg="9" md="8">
                  <v-row
                    v-for="(hp_type, index) in hp_type_items"
                    :key="index"
                    class="mb-3"
                    cols="12"
                  >
                    <v-toolbar color="teal  lighten-5" dense>
                      <v-toolbar-title
                        ><div class="font-weight-bold">
                          {{ getBetTypeText(hp_type.bet_type) }}
                        </div>
                        <div class="purple--text">
                          {{
                            getPrizeText(
                              hp_type.bet_type,
                              hp_type.prize_type,
                              hp_type.prize_1,
                              hp_type.prize_2
                            )
                          }}
                        </div>
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon>
                        <v-icon color="red" @click="OnDelHpInGroup(index)"
                          >mdi-trash-can-outline</v-icon
                        >
                      </v-btn>
                      <v-btn icon>
                        <v-icon color="success" @click="OnAddHpInGroup(index)"
                          >mdi-plus-circle-outline</v-icon
                        >
                      </v-btn>
                    </v-toolbar>

                    <v-card-text class="mt-0 pt-0 pl-1">
                      <v-chip
                        class="ma-2"
                        close
                        color="teal darken-1"
                        text-color="white"
                        close-icon="mdi-close-circle"
                        v-for="(hp_number, index_number) in hp_type.number"
                        :key="index_number"
                        @click:close="OnHpClose(index, index_number)"
                      >
                        <!-- <v-avatar left>
                          <v-icon>mdi-checkbox-marked-circle</v-icon>
                        </v-avatar> -->
                        {{ hp_number }}
                      </v-chip>
                    </v-card-text>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <div class="text-center">
            <v-btn text class="blue--text" @click="step_data = 1">
              ย้อนกลับ
            </v-btn>
            <v-btn color="primary" @click="step_data = 3">
              ต่อไป <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-5 pa-0" color="white" height="">
            <v-card-text>
              <v-row>
                <v-col xl="4" lg="4" md="4" cols="12" class="">
                  <v-row>
                    <v-col>
                      <p
                        class="indigo--text font-weight-bold text-decoration-underline"
                      >
                        เลือกการเปิดงวด
                      </p>
                    </v-col>
                  </v-row>

                  <v-radio-group v-model="radioOpen">
                    <v-row>
                      <v-col>
                        <v-radio label="เปิดทันที" value="1"></v-radio>
                        <hr />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-radio
                          class="mt-4"
                          label="สร้างรอเปิดเอง"
                          value="2"
                        ></v-radio>
                        <hr />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-radio
                          class="mt-4"
                          label="เปิดอัตโนมัติเวลา"
                          value="3"
                        ></v-radio>
                      </v-col>

                      <v-col cols="6">
                        <OpenDTAutoPicker
                          v-model="NewPeriod.OpenDTAuto"
                        ></OpenDTAutoPicker>
                      </v-col>
                      <v-col cols="6">
                        <OpenTimeAutoPicker
                          v-model="NewPeriod.OpenTimeAuto"
                        ></OpenTimeAutoPicker>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
                <!-- <v-divider vertical /> -->
                <v-col xl="8" lg="8" md="8" cols="12" class="">
                  <v-row>
                    <v-col>
                      <p
                        class="indigo--text font-weight-bold text-decoration-underline"
                      >
                        เรียงลำดับเลขอั้น
                      </p>
                    </v-col>
                  </v-row>

                  <v-row class="" cols="12">
                    <v-toolbar color="teal  lighten-5" dense>
                      <v-toolbar-title
                        ><div class="font-weight-bold">
                          เลขอั้นบน
                        </div>
                      </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="mt-0 pt-0 pl-1">
                      <v-chip
                        class="mt-2 mr-1"
                        color="teal darken-1"
                        text-color="white"
                        v-for="(HPTOP, index) in HP_TOP_LIST"
                        :key="index"
                      >
                        <v-avatar left>
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-avatar>
                        {{ HPTOP.number }}
                        <v-avatar right>
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-avatar>
                      </v-chip>
                    </v-card-text>
                  </v-row>

                  <v-row class="" cols="12">
                    <v-toolbar color="teal  lighten-5" dense>
                      <v-toolbar-title
                        ><div class="font-weight-bold">
                          เลขอั้น 2บน+ล่าง
                        </div>
                      </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="mt-0 pt-0 pl-1">
                      <v-chip
                        class="mt-2 mr-1"
                        color="teal darken-1"
                        text-color="white"
                        v-for="(HPDOWN, index) in HP_DOWN_LIST"
                        :key="index"
                      >
                        <v-avatar left>
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-avatar>
                        {{ HPDOWN.number }}
                        <v-avatar right>
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-avatar>
                      </v-chip>
                    </v-card-text>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <div class="text-center">
            <v-btn text class="blue--text" @click="step_data = 2">
              ย้อนกลับ
            </v-btn>
            <v-btn color="primary" @click="OnSave()">
              บันทึก <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-snackbar
      v-model="showprocess.show"
      :bottom="showprocess.y === 'bottom'"
      :color="showprocess.color"
      :left="showprocess.x === 'left'"
      :multi-line="showprocess.mode === 'multi-line'"
      :right="showprocess.x === 'right'"
      :timeout="showprocess.timeout"
      :top="showprocess.y === 'top'"
      :vertical="showprocess.mode === 'vertical'"
    >
      {{ showprocess.text }}
    </v-snackbar>
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import PeriodDtPicker from "@/components/Controls/cDatePicker";
import CloseDTShowPicker from "@/components/Controls/cDatePicker";
import CloseTimeShowPicker from "@/components/Controls/cTimePicker";
import CloseDTAutoPicker from "@/components/Controls/cDatePicker";
import CloseTimeAutoPicker from "@/components/Controls/cTimePicker";
import OpenDTAutoPicker from "@/components/Controls/cDatePicker";
import OpenTimeAutoPicker from "@/components/Controls/cTimePicker";
// import Hp_NumberVue from "../../Data/Hp_Number.vue";
// import ExportPagesDatatable from "@/components/Data/Export_Page/Dashboard/Datatable";

export default {
  name: "Export_Pages",
  mixins: [globalFunctionMixin],
  components: {
    PeriodDtPicker,
    CloseDTShowPicker,
    CloseTimeShowPicker,
    CloseDTAutoPicker,
    CloseTimeAutoPicker,
    OpenDTAutoPicker,
    OpenTimeAutoPicker,
  },

  mounted() {
    this.ckLogin();
    window.scrollTo(0, 0);
    this.selectedType = 3;
    this.selectedPrize = 1;
  },

  computed: {},
  methods: {
    OnSave() {
      if (this.Sending == true) return;
      this.Sending = true;
      this.OnCreatePeriod();
    },
    async OnCreatePeriod() {
      let HP_TYPE = [];
      let HP_NUMBER = [];
      let PRIZE_TYPE = [];
      let PRIZE1 = [];
      let PRIZE2 = [];
      let INDEX = [];

      for (let n1 = 0; n1 < this.hp_type_items.length; n1++) {
        for (let n2 = 0; n2 < this.hp_type_items[n1]["number"].length; n2++) {
          HP_TYPE.push(this.hp_type_items[n1]["bet_type"]);
          PRIZE_TYPE.push(this.hp_type_items[n1]["prize_type"]);
          PRIZE1.push(this.hp_type_items[n1]["prize_1"]);
          PRIZE2.push(this.hp_type_items[n1]["prize_2"]);
          HP_NUMBER.push(this.hp_type_items[n1]["number"][n2]);
          INDEX.push(this.hp_type_items[n1]["hp_index"][n2]);
        }
      }

      let credentials = {
        PeriodDT: this.NewPeriod.Period,
        CloseDTShow: this.NewPeriod.CloseDTShow,
        CloseTimeShow: this.NewPeriod.CloseTimeShow,
        IsAutoClose: this.NewPeriod.IsAutoClose,
        CloseDTAuto: this.NewPeriod.CloseDTAuto,
        CloseTimeAuto: this.NewPeriod.CloseTimeAuto,
        Status: this.radioOpen,
        OpenDTAuto: this.OpenDTAuto,
        OpenTimeAuto: this.OpenDTAuto,
        HP_TYPE: HP_TYPE.toString(),
        HP_NUMBER: HP_NUMBER.toString(),
        PRIZE_TYPE: PRIZE_TYPE.toString(),
        PRIZE1: PRIZE1.toString(),
        PRIZE2: PRIZE2.toString(),
        INDEX: INDEX.toString(),
      };
      //console.log(this.hp_type_items);
      console.log(credentials);
      //   console.log(JSON.stringify(credentials));

      await this.$store.dispatch("period/new_period", credentials).then(
        (response) => {
          console.log(response);
          switch (response.Status) {
            case 1: //งวดใหม่ ไปหน้าเลขอั้น
              sessionStorage.setItem("period", response.PeriodDT);
              this.$router.push({
                path: "new_period_success",
                query: {
                  period: response.PeriodDT,
                },
              });
              break;
            case 2: //สร้างรอไม่เปลี่ยน ไปหน้าจัดการงวด
              sessionStorage.setItem("period", response.PeriodDT);
              this.$router.push({
                path: "new_period_success",
                query: {
                  period: response.PeriodDT,
                },
              });
              break;
            case 3: //สร้างรอเปิดอัตโนมัติ ไม่เปลี่ยน ไปหน้าจัดการงวด
              sessionStorage.setItem("period", response.PeriodDT);
              this.$router.push({
                path: "new_period_success",
                query: {
                  period: response.PeriodDT,
                },
              });
              break;
          }
          this.Sending = false;
        },
        (error) => {
          console.log(error);
          this.Sending = false;
        }
      );
    },
    period_th() {
      return this.getFullPeriodThai(sessionStorage.getItem("period"));
    },
    changedValue: function(value) {
      //receive the value selected (return an array if is multiple)
      console.log(value);
    },
    getBetTypeText(bet_type) {
      switch (bet_type) {
        case 3:
          return "3 ตัวบน x 6 หาง x โต๊ด";
        case 1:
          return "3 ตัวบน";
        case 4:
          return "2 ตัว บน + ล่าง";
        case 2:
          return "3 ตัวโต๊ด";
      }
    },
    getPrizeText(bet_type, prize_type, Prize1, Prize2) {
      switch (prize_type) {
        case 1:
          return "จ่ายครึ่ง";
        case 2:
          if (bet_type == 3) {
            return "จ่ายตรง " + Prize1 + "  โต๊ด " + Prize2;
          } else if (bet_type == 4) {
            return "จ่าย " + Prize1;
          }
          //<v-icon>mdi-cash-100</v-icon> ตรง 100
          //                  <v-icon>mdi-swap-horizontal-circle</v-icon> โต๊ด
          //                  30
          //   return "บาทละ " + Prize1;
          break;
        case 3:
          if (bet_type == 3) {
            return "จ่ายตรง " + Prize1 + "%  โต๊ด " + Prize2 + "%";
          } else if (bet_type == 4) {
            return "จ่าย " + Prize1 + "%";
          }

          break;
        case -1:
          return "ปิดรับ";
      }
    },
    //BETTYPE
    OnAddBetType() {
      if (/^\d+$/.test(this.BetNumber) == false) {
        this.$alert("เลขอั้นต้องเป็นตัวเลขเท่านั้น", "ผิดพลาด", "error").then(
          () => {
            this.$refs.txtBetNumber.focus();
          }
        );
        return;
      }

      if (this.BetNumber == "") {
        this.$alert("ต้องใส่เลขที่ต้องการอั้น", "ผิดพลาด", "error").then(() => {
          this.$refs.txtBetNumber.focus();
        });
        return;
      }

      const num_size_rule = [3, 3, 3, 2];
      if (num_size_rule[this.selectedType - 1] != this.BetNumber.length) {
        this.$alert(
          "ต้องใส่เลขอั้น " +
            num_size_rule[this.selectedType - 1] +
            " หลักเท่านั้น",
          "ผิดพลาด",
          "error"
        ).then(() => {
          this.$refs.txtBetNumber.focus();
        });
        return;
      }

      //เช็คว่าเป็นตรงโต๊ดมั้ย
      if (this.selectedType == 3 && this.selectedPrize != 1) {
        //3 = 2 ตัวบน+ล่าง
        if (parseInt(this.Prize1) <= 0 || this.Prize1 == "") {
          this.$alert("ต้องใส่ราคาตรง", "ผิดพลาด", "error").then(() => {
            this.$refs.txtPrize1.focus();
          });
          return;
        }
        if (parseInt(this.Prize2) <= 0 || this.Prize2 == "") {
          this.$alert("ต้องใส่ราคาโต๊ด", "ผิดพลาด", "error").then(() => {
            this.$refs.txtPrize2.focus();
          });
          return;
        }
      }

      let dupplicate_number = false;
      for (let n1 = 0; n1 < this.hp_type_items.length; n1++) {
        for (let n2 = 0; n2 < this.hp_type_items[n1]["number"].length; n2++) {
          if (
            this.isEqualSwapNumber(
              this.BetNumber,
              this.hp_type_items[n1]["number"][n2]
            )
          ) {
            this.$alert(
              "เลขนี้มีอยู่ในเลขอั้นอยู่แล้ว",
              "ผิดพลาด",
              "error"
            ).then(() => {
              this.$refs.txtBetNumber.focus();
            });
            return;
          }
        }
      }

      let repeat = false;
      for (let n = 0; n < this.hp_type_items.length; n++) {
        if (
          this.selectedType == this.hp_type_items[n]["bet_type"] &&
          this.selectedPrize == this.hp_type_items[n]["prize_type"] &&
          this.Prize1 == this.hp_type_items[n]["prize_1"] &&
          this.Prize2 == this.hp_type_items[n]["prize_2"]
        ) {
          //add number
          this.hp_type_items[n]["number"].push(this.BetNumber);
          this.hp_type_items[n]["hp_index"].push(this.hp_index);
          this.hp_index++;
          repeat = true;
          break;
        }
      }
      console.log(this.hp_type_items);

      //add new hp_type
      if (!repeat) {
        let NewHP = [];
        NewHP["bet_type"] = this.selectedType;
        NewHP["prize_type"] = this.selectedPrize;
        NewHP["prize_1"] = this.Prize1;
        NewHP["prize_2"] = this.Prize2;
        NewHP["number"] = [this.BetNumber];
        NewHP["hp_index"] = [this.hp_index];
        this.hp_type_items.push(NewHP);
        this.hp_index++;
      }

      this.CalHpShowList();

      this.ShowSuccess("เพิ่มเลขอั้น " + this.BetNumber + " สำเร็จ");

      this.BetNumber = "";
      this.$refs.txtBetNumber.focus();

      //const searchIndex = this.hp_item.findIndex((car) => car.index == "X5");

      //   console.log(this.HP_TOP_LIST);
    },
    CalHpShowList() {
      this.HP_TOP_LIST = [];
      this.HP_DOWN_LIST = [];
      for (let n1 = 0; n1 < this.hp_type_items.length; n1++) {
        for (let n2 = 0; n2 < this.hp_type_items[n1]["number"].length; n2++) {
          if (this.hp_type_items[n1]["bet_type"] == 3) {
            let hp = [];
            hp["index"] = this.hp_type_items[n1]["hp_index"][n2];
            hp["number"] = this.hp_type_items[n1]["number"][n2];
            this.HP_TOP_LIST.push(hp);
          } else if (this.hp_type_items[n1]["bet_type"] == 4) {
            let hp = [];
            hp["index"] = this.hp_type_items[n1]["hp_index"][n2];
            hp["number"] = this.hp_type_items[n1]["number"][n2];
            this.HP_DOWN_LIST.push(hp);
          }
        }
      }

      this.HP_TOP_LIST.sort(function(a, b) {
        return a.index - b.index;
      });

      this.HP_DOWN_LIST.sort(function(a, b) {
        return a.index - b.index;
      });
    },
    OnHpClose(index, index_number) {
      this.$confirm("ทำการลบเลขอั้น", "กดยืนยันเพื่อลบ", "question")
        .then(() => {
          this.hp_type_items[index]["number"].splice(index_number, 1);

          if (this.hp_type_items[index]["number"].length == 0) {
            this.hp_type_items.splice(index, 1);
          }
          this.CalHpShowList();
          this.ShowSuccess("ลบเลขอั้นเรียบร้อย");
        })
        .catch(() => {});
    },
    OnAddHpInGroup(index) {
      this.selectedType = parseInt(this.hp_type_items[index].bet_type);
      this.selectedPrize = parseInt(this.hp_type_items[index].prize_type);
      this.Prize1 = parseInt(this.hp_type_items[index].prize_1);
      this.Prize2 = parseInt(this.hp_type_items[index].prize_2);
      this.$refs.txtBetNumber.focus();
    },
    OnDelHpInGroup(index) {
      this.$confirm("ทำการลบชุดเลขอั้น", "กดยืนยันเพื่อลบ", "question")
        .then(() => {
          this.hp_type_items.splice(index, 1);
          this.ShowSuccess("ลบเลขอั้นเรียบร้อย");
        })
        .catch(() => {});
    },
    ShowSuccess(Text) {
      this.showprocess.text = Text;
      this.showprocess.color = "green darken-1";
      this.showprocess.show = true;
    },
  },

  data: () => ({
    HostName: sessionStorage.getItem("nickname"),
    step_data: 1,
    //PeriodDT:"",
    Sending: false,

    showprocess: {
      show: false,
      text: "",
      timeout: 1000,
      color: "red",
      mode: "",
      x: null,
      y: "top",
    },

    NewPeriod: {
      Period: "",
      //   OpenClose: "",
      //   OpenTime: "",
      CloseDTShow: "",
      CloseTimeShow: "12:00",
      IsAutoClose: false,
      CloseDTAuto: "",
      CloseTimeAuto: "12:00",
      OpenDTAuto: "",
      OpenTimeAuto: "12.00",
    },
    //hp
    hp_index: 0,
    selectedType: { value: 3 },
    selectedPrize: { value: 1 },
    Prize1: 100,
    Prize2: 30,
    BetNumber: "",
    type_items: [
      { text: "3 ตัวบน x 6 หาง x โต๊ด", value: 3 },
      //   { text: "3 ตัวบน x 6 หาง", value: 1 },
      { text: "2 ตัว บน + ล่าง", value: 4 },
      //   { text: "3 ตัวโต๊ด", value: 2 },
    ],
    prize_item: [
      { text: "จ่ายครึ่ง", value: 1 },
      { text: "บาทละ", value: 2 },
      { text: "ปิดรับ", value: -1 },
      //   { text: "เปอร์เซ็นต์", value: 3 },
    ],
    hp_type_items: [],
    HP_TOP_LIST: [],
    HP_DOWN_LIST: [],
    //Confirm
    radioOpen: "1",
  }),
};
</script>

<style scoped></style>
